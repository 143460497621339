import contributionShape_1 from "../asset/img/images/contribution_shape01.png";
import contributionShape_2 from "../asset/img/images/contribution_shape02.png";
import BACKGROUND from "../asset/img/banner/banner_bg.png";
import { CountdownClock } from "./CountDownClock";
import { Box, Container, Typography } from "@mui/material";
import { getFormattedNumberString } from "../common/helper";

type Props = {
  endDate?: Date;
  totalStaked: number;
  totalStaker: number;
  totalPaid: number;
};

export const Banner = ({
  endDate,
  totalStaked,
  totalStaker,
  totalPaid,
}: Props) => {
  return (
    <section
      className="banner-area banner-bg"
      style={{ backgroundImage: `url(${BACKGROUND})` }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              <h2 className="title">
                <span>Put&nbsp; your&nbsp; UPC&nbsp; to&nbsp; work</span>
              </h2>
              <p>Earn UPC through staking</p>
              <div className="banner-countdown-wrap">
                <div className="coming-time" data-countdown="2024/8/29">
                  {endDate && <CountdownClock endTime={endDate} />}
                </div>
              </div>
            </div>
          </div>
          <Container maxWidth="xl">
            <Box className="statistics-wrapper">
              <Box className="statistics-item-wrapper">
                <Typography variant="h3" className="statistics-item-title">
                  Total staked tokens
                </Typography>
                <Typography className="statistics-item-content">
                  {getFormattedNumberString(totalStaked)} UPC
                </Typography>
              </Box>
              <Box className="statistics-item-wrapper">
                <Typography variant="h3" className="statistics-item-title">
                  Total Stakers
                </Typography>
                <Typography className="statistics-item-content">
                  {getFormattedNumberString(totalStaker)}
                </Typography>
              </Box>
              <Box className="statistics-item-wrapper">
                <Typography variant="h3" className="statistics-item-title">
                  Total rewards paid
                </Typography>
                <Typography className="statistics-item-content">
                  {getFormattedNumberString(totalPaid)}
                </Typography>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
      <div className="banner-scroll-down">
        <a href="#contribution" className="section-link">
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
      <div className="banner-shape-wrap">
        <img src={contributionShape_1} alt="" className="leftToRight" />
        <img src={contributionShape_2} alt="" className="alltuchtopdown" />
      </div>
    </section>
  );
};
