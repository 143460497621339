import { createContext } from "react";

export interface WalletContextValue {
  isConnected: boolean;
  currentAddress?: string;
  connect?: () => void;
  disconnect?: () => void;
}

export const WalletContext = createContext<null | WalletContextValue>(null);
