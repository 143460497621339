import BigNumber from "bignumber.js";
import { ActivityService } from "../openapi";
import { RESPONSE_CODE } from "./const";

export const getFormattedNumberString = (value: number | undefined) => {
  if (!value) return "0";

  const simpleValue = parseFloat(value.toFixed(2));

  const template = new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 });

  return template.format(simpleValue);
};

export const getSimpleWalletAddress = (
  address: string | undefined,
  firstlength: number,
  lastLength?: number
) => {
  if (!address) {
    return "";
  }

  return `${address.substring(0, firstlength + 2)}...${address.substring(
    address.length - (lastLength ?? firstlength)
  )}`;
};

export const waitTx = (txHash: string, onSuccess: any, onFailed: any) => {
  ActivityService.getActivity(txHash)
    .then((res) => {
      if (res) {
        onSuccess();
        return;
      }
    })
    .catch((err) => {
      if (err.body.statusCode === RESPONSE_CODE.NOT_FOUNT) {
        setTimeout(() => waitTx(txHash, onSuccess, onFailed), 1000);
      } else {
        onFailed();
      }
    });
};

export const getReward = (periods: number, apy: number, amount: number) => {
  if (isNaN(amount)) {
    return 0;
  }
  return (((amount * apy) / 100) * periods) / 365;
};

export const getFormattedDatetimeString = (date: Date, onlyDate?: boolean) => {
  if (onlyDate) {
    return (
      date.toUTCString().substring(8, 11) +
      " " +
      date.toUTCString().substring(5, 7) +
      ", " +
      date.toUTCString().substring(12, 16)
    );
  }

  return (
    date.toUTCString().substring(17, 25) +
    " " +
    date.toUTCString().substring(8, 11) +
    " " +
    date.toUTCString().substring(5, 7) +
    ", " +
    date.toUTCString().substring(12, 16)
  );
};

export const getFormattedDayMonthYear = (date: Date) => {
  return (
    date.toUTCString().substring(8, 11) +
    " " +
    date.toUTCString().substring(5, 7) +
    ", " +
    date.toUTCString().substring(12, 16)
  );
};

export const fromDecimalData = (value: string | number) => {
  let ret = BigNumber(value);
  ret = ret.dividedBy(Math.pow(10, 5));
  return ret.toString();
};

export const toDecimalData = (value: string | number) => {
  let ret = BigNumber(value);
  ret = ret.multipliedBy(Math.pow(10, 5));
  return ret.toString();
};
