export interface MenuItem {
  id: number;
  title: string;
  link: string;
  has_dropdown: boolean;
  sub_menus?: {
    link: string;
    title: string;
  }[];
}

export const MENU_DATA: MenuItem[] = [
  {
    id: 1,
    has_dropdown: false,
    title: "Home",
    link: "/",
  },
  {
    id: 2,
    has_dropdown: false,
    title: "Stake",
    link: "/stake",
  },
  {
    id: 3,
    has_dropdown: false,
    title: "Reward",
    link: "/reward",
  },
  {
    id: 4,
    has_dropdown: false,
    title: "Referral",
    link: "/referral",
  },
];
