import { Checkbox, FormControlLabel } from "@mui/material";

type Props = {
  className?: string;
  label: string;
  checked: boolean;
  handleClick: any;
};

export const CheckBox = ({ className, label, checked, handleClick }: Props) => {
  return (
    <FormControlLabel
      className={className}
      sx={{
        ".MuiFormControlLabel-label ": { fontSize: "12px", color: "white" },
        ".MuiSvgIcon-root": { color: "#44a08d" },
      }}
      control={
        <Checkbox
          checked={checked}
          onClick={() => handleClick()}
          name="jason"
        />
      }
      label={label}
    />
  );
};
