import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { useRecoilState } from "recoil";
import "../asset/style/reward.scss";
import { CheckBox } from "../component/CheckBox";
import { RewardRecord } from "../component/RewardRecord";
import { useWallletContext } from "../hook/useWalletContext";
import { MainLayout } from "../layout/MainLayout";
import { StakingService } from "../openapi";
import { ShowProgress } from "../state/ShowProgress";

const PAGE_SIZE = 10;

export const Reward = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 720px)",
  });

  const { currentAddress } = useWallletContext();

  const [, setShowProgress] = useRecoilState(ShowProgress);

  const [records, setRecords] = useState<any[]>([]);

  const [status, setStatus] = useState<boolean | undefined>(undefined);

  const [showMore, setShowMore] = useState<boolean>(false);

  useEffect(() => {
    if (!currentAddress) {
      return;
    }

    setShowMore(true);
    getRecords(0);
  }, [currentAddress, status]);

  const getRecords = (offset: number) => {
    if (offset === 0) {
      setShowMore(true);
    }

    StakingService.listStakingRecords(
      offset,
      PAGE_SIZE,
      currentAddress,
      undefined,
      undefined,
      undefined,
      status
    )
      .then((res) => {
        setShowProgress(false);
        if (res[0].length < PAGE_SIZE) {
          // toast.info("There's no more staking record.");
          setShowMore(false);
        }

        if (offset === 0) {
          setRecords(res[0]);
        } else {
          setRecords([...records, ...res[0]]);
        }
      })
      .catch((err) => {
        setShowProgress(false);
        console.log(err);
      });
  };

  const handleLoadMore = () => {
    setShowProgress(true);
    getRecords(records.length);
  };

  return (
    <MainLayout>
      <Container maxWidth="md" className="full-height">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb-20 mt-160">
              <h2 className="title">Reward History</h2>
            </div>
          </div>
        </div>
        <Typography className="reward-page-description">
          Track your UPC staking rewards.
        </Typography>
        <Card className="reward-page-history-card">
          <Box className="reward-page-history-card-header">
            <Typography className="reward-page-history-title">
              Reward History
            </Typography>
            <CheckBox
              label="All"
              className="reward-page-history-filter"
              checked={status === undefined}
              handleClick={() => setStatus(undefined)}
            />
            <CheckBox
              label="Claimed"
              className="reward-page-history-filter"
              checked={status === true}
              handleClick={() => setStatus(true)}
            />
            <CheckBox
              label="Not Claimed"
              className="reward-page-history-filter"
              checked={status === false}
              handleClick={() => setStatus(false)}
            />
          </Box>
          <Box className="reward-page-history-card-divider" />
          {isMobile ? (
            <Grid container spacing={2}>
              {records.map((record, index) => {
                return <RewardRecord key={record.id} record={record} />;
              })}
            </Grid>
          ) : (
            <>
              {records.map((record, index) => {
                return <RewardRecord key={record.id} record={record} />;
              })}
            </>
          )}

          <Box display="flex">
            {showMore && (
              <Button
                className="reward-page-history-load-more-button"
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            )}
            {records.length === 0 && (
              <Typography className="reward-page-no-data-text">
                {currentAddress
                  ? "No Staking Records"
                  : "Please connect the wallet."}
              </Typography>
            )}
          </Box>
        </Card>
      </Container>
    </MainLayout>
  );
};
