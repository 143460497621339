import { Link } from "react-router-dom";
import LOGO from "../asset/img/logo/logo.png";
import MAIL from "../asset/img/icons/mail.png";
import MEDIUM from "../asset/img/icons/medium.png";
import TWITTER from "../asset/img/icons/twitter.png";
import TELEGRAM from "../asset/img/icons/telegram.png";
import DISCORD from "../asset/img/icons/discord.png";
import GITHUB from "../asset/img/icons/github.png";

export const Footer = () => {
  return (
    <>
      <div className="footer-wrapper-border" />
      <div className="footer-wrapper">
        <div className="logo footer-logo">
          <Link to="/">
            <img src={LOGO} alt="Logo" height={60} />
          </Link>
        </div>
        <div className="footer-sns-wrapper">
          <div className="footer-sns-item">
            <Link to="mailto:info@upcx.io">
              <img src={MAIL} />
            </Link>
          </div>
          <div className="footer-sns-item">
            <Link to="https://medium.com/@UPCX-Platforms">
              <img src={MEDIUM} />
            </Link>
          </div>
          <div className="footer-sns-item">
            <Link to="https://twitter.com/Upcxofficial">
              <img src={TWITTER} />
            </Link>
          </div>
          <div className="footer-sns-item">
            <Link to="https://t.me/UPCXofficial">
              <img src={TELEGRAM} />
            </Link>
          </div>
          <div className="footer-sns-item">
            <Link to="http://dsc.gg/upcx">
              <img src={DISCORD} />
            </Link>
          </div>
          <div className="footer-sns-item">
            <Link to="https://github.com/UPCX-TEAM-B">
              <img src={GITHUB} />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
