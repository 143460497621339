import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { BrowserProvider, Contract, Interface } from "ethers";
import { useEffect, useState } from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { DAY, METAMASK_ERROR_CODE } from "../common/const";
import {
  getFormattedDayMonthYear,
  getFormattedNumberString,
  getSimpleWalletAddress,
  waitTx,
} from "../common/helper";
import STAKING_ABI from "../common/Staking_abi.json";
import { ShowProgress } from "../state/ShowProgress";

type Props = {
  record: any;
};

export const RewardRecord = ({ record }: Props) => {
  const isSmallMobile = useMediaQuery({
    query: "(max-width: 500px)",
  });

  const { walletProvider } = useWeb3ModalProvider();

  const [, setShowProgress] = useRecoilState(ShowProgress);

  const [data, setData] = useState<any>(record);

  const handleClaim = async () => {
    if (record.stakedAt * 1000 + record.period * DAY > Date.now()) {
      toast.warn(
        `This staking is able to claim after ${getFormattedDayMonthYear(
          new Date(record.stakedAt * 1000 + record.period * DAY)
        )}`
      );
      return;
    }

    const provider = new BrowserProvider(walletProvider!);
    const signer = await provider.getSigner();

    const stakingContract = new Contract(
      process.env.REACT_APP_STAKING_CONTRACT_ADDRESS!,
      new Interface(STAKING_ABI).format(false),
      signer
    );

    setShowProgress(true);
    stakingContract
      .claim(record.stakingID)
      .then((tx) => {
        waitTx(
          tx.hash,
          () => {
            setShowProgress(false);
            toast.success("UPC token has been claimed successfully.");

            let data = record;
            data.claimed = true;
            setData(data);
          },
          () => {
            setShowProgress(false);
            toast.error("Unexpected exception");
          }
        );
      })
      .catch((err) => {
        setShowProgress(false);
        if (err.code === METAMASK_ERROR_CODE.USER_REJECT) {
          return;
        }

        toast.error("Transaction reverted");
      });
  };

  return (
    <>
      <MediaQuery minWidth={721}>
        <Box className="reward-record-wrapper">
          <Box className="reward-record-id-container">
            <Typography className="reward-record-detail-text">
              #{record.id}
            </Typography>
          </Box>
          <Box className="reward-record-period-container">
            <Typography className="reward-record-detail-text">
              Period(UTC+0): {record.period} Days
            </Typography>
            <Typography className="reward-record-detail-text">
              {getFormattedDayMonthYear(new Date(record.stakedAt * 1000))} ~{" "}
              {getFormattedDayMonthYear(
                new Date(
                  record.stakedAt * 1000 + 3600 * 24 * record.period * 1000
                )
              )}
            </Typography>
          </Box>
          <Box className="reward-record-amount-container">
            <Typography className="reward-record-detail-text">
              Stake Amount
            </Typography>
            <Typography className="reward-record-detail-text">
              {getFormattedNumberString(record.amount)} UPC
            </Typography>
          </Box>
          <Box className="reward-record-apy-container">
            <Typography className="reward-record-detail-text">APY</Typography>
            <Typography className="reward-record-detail-text">
              {record.apy}%
            </Typography>
          </Box>
          <Box className="reward-record-reward-container">
            <Typography className="reward-record-detail-text">
              Reward
            </Typography>
            <Typography className="reward-record-detail-text">
              {getFormattedNumberString(record.reward)} UPC
            </Typography>
          </Box>
          <Box className="reward-record-referral-container">
            {record.referralCode && (
              <>
                <Typography className="reward-record-detail-text">
                  Referral Code: {record.referralCode}
                </Typography>
                <Typography className="reward-record-detail-text">
                  {getSimpleWalletAddress(record.referrer, 6, 6)}
                </Typography>
              </>
            )}
          </Box>

          <Box width="80px" display="flex">
            {data.claimed ? (
              <Typography className="reward-page-claimed-text">
                Claimed
              </Typography>
            ) : (
              <Button
                className="reward-record-claim-button"
                onClick={handleClaim}
              >
                Claim
              </Button>
            )}
          </Box>
        </Box>
      </MediaQuery>
      <MediaQuery maxWidth={720}>
        <Grid item xs={isSmallMobile ? 12 : 6}>
          <Card className="reward-record-card">
            <Box className="reward-record-card-content">
              <Box className="reward-record-card-detail-wrapper">
                <Typography className="reward-record-card-detail-center-title">
                  {getFormattedDayMonthYear(new Date(record.stakedAt * 1000))} ~{" "}
                  {getFormattedDayMonthYear(
                    new Date(
                      record.stakedAt * 1000 + 3600 * 24 * record.period * 1000
                    )
                  )}
                  (UTC+0)
                </Typography>
              </Box>
              <Box className="reward-record-card-detail-wrapper">
                <Typography className="reward-record-card-detail-title">
                  Stake Amount
                </Typography>
                <Typography className="reward-record-card-detail-text">
                  {getFormattedNumberString(record.amount)} UPC
                </Typography>
              </Box>
              <Box className="reward-record-card-detail-wrapper">
                <Typography className="reward-record-card-detail-title">
                  APY
                </Typography>
                <Typography className="reward-record-card-detail-text">
                  {record.apy}%
                </Typography>
              </Box>
              <Box className="reward-record-card-detail-wrapper">
                <Typography className="reward-record-card-detail-title">
                  Reward
                </Typography>
                <Typography className="reward-record-card-detail-text">
                  {getFormattedNumberString(record.reward)} UPC
                </Typography>
              </Box>
              {record.referralCode && (
                <>
                  <Box className="reward-record-card-detail-wrapper">
                    <Typography className="reward-record-card-detail-title">
                      Referral Code
                    </Typography>
                    <Typography className="reward-record-card-detail-text">
                      {record.referralCode}
                    </Typography>
                  </Box>
                  <Box className="reward-record-card-detail-wrapper">
                    <Typography className="reward-record-card-detail-title">
                      Referrer
                    </Typography>
                    <Typography className="reward-record-card-detail-text">
                      {getSimpleWalletAddress(record.referrer, 6, 6)}
                    </Typography>
                  </Box>
                </>
              )}
              <Box
                className="reward-record-card-detail-wrapper"
                sx={{
                  marginBottom: "0px !important",
                  marginTop: "auto !important",
                }}
              >
                {data.claimed ? (
                  <Typography className="reward-page-claimed-text">
                    Claimed
                  </Typography>
                ) : (
                  <Button
                    className="reward-record-card-claim-button"
                    onClick={handleClaim}
                  >
                    Claim
                  </Button>
                )}
              </Box>
            </Box>
          </Card>
        </Grid>
      </MediaQuery>
    </>
  );
};
