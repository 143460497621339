import "../asset/style/home.scss";
import { Banner } from "../component/Banner";
import { MainLayout } from "../layout/MainLayout";
import { Contribution } from "../component/Contribution";
import { FAQ } from "../component/FAQ";
import { Box, Container, Typography } from "@mui/material";
import { StakingItem } from "../component/StakingItem";
import { HowItWorks } from "../component/HowItWorks";
import { useEffect, useState } from "react";
import { StakingService, StatusService } from "../openapi";

export const Home = () => {
  const [endDate, setEndDate] = useState<Date>();

  const [totalStaked, setTotalStaked] = useState<number>(0);
  const [totalStaker, setTotalStaker] = useState<number>(0);
  const [totalPaid, setTotalPaid] = useState<number>(0);
  const [totalStakedByPeriods, setTotalStakedByPeriods] = useState<number[]>([
    0, 0, 0,
  ]);
  const [totalStakersByPeriods, setTotalStakersByPeriods] = useState<number[]>([
    0, 0, 0,
  ]);

  useEffect(() => {
    StatusService.getPeriods()
      .then((res) => {
        const periods = JSON.parse(res.value);

        let endDate = new Date();
        for (let i = 0; i < periods.length; i++) {
          for (let j = 0; j < periods[i].length; j++) {
            if (endDate < new Date(periods[i][j].to * 1000)) {
              endDate = new Date(periods[i][j].to * 1000);
            }
          }
        }

        setEndDate(endDate);
      })
      .catch((err) => {
        console.log(err);
      });

    StakingService.getTotalStakingInfo()
      .then((res) => {
        setTotalStaked(res.total.stakeAmount);
        setTotalStaker(res.total.stakerCount);
        setTotalPaid(res.total.totalPaidReward);

        let totalStakedByPeriods = [];
        totalStakedByPeriods.push(res.periods.period180.stakeAmount);
        totalStakedByPeriods.push(res.periods.period90.stakeAmount);
        totalStakedByPeriods.push(res.periods.period30.stakeAmount);
        setTotalStakedByPeriods(totalStakedByPeriods);

        let totalStakersByPeriods = [];
        totalStakersByPeriods.push(res.periods.period180.stakerCount);
        totalStakersByPeriods.push(res.periods.period90.stakerCount);
        totalStakersByPeriods.push(res.periods.period30.stakerCount);
        setTotalStakersByPeriods(totalStakersByPeriods);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <MainLayout>
      <Banner
        endDate={endDate}
        totalPaid={totalPaid}
        totalStaked={totalStaked}
        totalStaker={totalStaker}
      />
      <Contribution totalStaked={totalStaked} />
      <Container maxWidth="xl">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb-20 mt-50">
              <h2 className="title">Staking&nbsp; Plans</h2>
            </div>
          </div>
        </div>
        <Box className="staking-items-container">
          <StakingItem
            period={180}
            apy={100}
            totalStaked={totalStakedByPeriods[0]}
            totalStakers={totalStakersByPeriods[0]}
          />
          <StakingItem
            period={90}
            apy={50}
            totalStaked={totalStakedByPeriods[1]}
            totalStakers={totalStakersByPeriods[1]}
          />
          <StakingItem
            period={30}
            apy={20}
            totalStaked={totalStakedByPeriods[2]}
            totalStakers={totalStakersByPeriods[2]}
          />
        </Box>
      </Container>
      <HowItWorks />
      <FAQ />
    </MainLayout>
  );
};
