import {
  createWeb3Modal,
  defaultConfig,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import axios from "axios";
import { PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { WalletContext } from "../context/WalletContext";

export const WalletProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { address, isConnected } = useWeb3ModalAccount();

  useEffect(() => {
    const chainInfo = {
      chainId: parseInt(process.env.REACT_APP_CHAIN_ID!),
      name: process.env.REACT_APP_CHAIN_NAME!,
      currency: process.env.REACT_APP_CHAIN_CURRENCY!,
      explorerUrl: process.env.REACT_APP_CHAIN_EXPLORER!,
      rpcUrl: process.env.REACT_APP_CHAIN_RPC!,
    };

    const metadata = {
      name: "Staking",
      description: "dApp for the escrow system",
      url: "https://upcx-staking.io",
      icons: ["https://avatars.mywebsite.com/"],
    };

    createWeb3Modal({
      ethersConfig: defaultConfig({ metadata }),
      chains: [chainInfo],
      projectId: process.env.REACT_APP_WALLET_PROJECT_ID!,
      themeVariables: {
        "--w3m-accent": "#44a08d",
      },
    });
  }, []);

  return (
    <WalletContext.Provider value={{ isConnected, currentAddress: address }}>
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
