/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StakingService {
    /**
     * @param offset
     * @param limit
     * @param staker
     * @param referrer
     * @param referralCode
     * @param from
     * @param to
     * @param claimed
     * @param claimedReferralFee
     * @returns any
     * @throws ApiError
     */
    public static listReferralStakingRecords(
        offset?: number,
        limit: number = 1,
        staker?: string,
        referrer?: string,
        referralCode?: string,
        from?: number,
        to?: number,
        claimed?: boolean,
        claimedReferralFee?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/staking/referral',
            query: {
                'offset': offset,
                'limit': limit,
                'staker': staker,
                'referrer': referrer,
                'referralCode': referralCode,
                'from': from,
                'to': to,
                'claimed': claimed,
                'claimedReferralFee': claimedReferralFee,
            },
        });
    }
    /**
     * @param offset
     * @param limit
     * @param staker
     * @param referralCode
     * @param from
     * @param to
     * @param claimed
     * @param claimedReferralFee
     * @returns any
     * @throws ApiError
     */
    public static listStakingRecords(
        offset?: number,
        limit: number = 1,
        staker?: string,
        referralCode?: string,
        from?: number,
        to?: number,
        claimed?: boolean,
        claimedReferralFee?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/staking',
            query: {
                'offset': offset,
                'limit': limit,
                'staker': staker,
                'referralCode': referralCode,
                'from': from,
                'to': to,
                'claimed': claimed,
                'claimedReferralFee': claimedReferralFee,
            },
        });
    }
    /**
     * @param address
     * @returns any
     * @throws ApiError
     */
    public static getTotalStakingInfoByAddress(
        address: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/staking/total_staking_info/{address}',
            path: {
                'address': address,
            },
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public static getTotalStakingInfo(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/staking/total_staking_info',
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static getStakingRecord(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/staking/{id}',
            path: {
                'id': id,
            },
        });
    }
}
