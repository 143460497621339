export const RESPONSE_CODE = {
  NOT_FOUNT: 404,
};

export const METAMASK_ERROR_CODE = {
  USER_REJECT: "ACTION_REJECTED",
};

// export const DAY = 1000; // For test
export const DAY = 3600 * 24 * 1000;

export const LOCAL_STRAGE_KEY = {
  HIDE_REGION_MODAL: "hide_region_modal",
};
