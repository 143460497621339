import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

type Props = {
  open: boolean;
  handleClose: any;
};

export const RegionModal = ({ open, handleClose }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        handleClose();
      }}
      maxWidth="xs"
    >
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="region-modal-text"
        >
          The cryptocurrency used in the Service is not listed on any
          cryptocurrency exchange in Japan.
          <br />
          Residents of Japan cannot purchase the applicable cryptocurrency.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="region-modal-button" onClick={() => handleClose()}>
          Go to the site
        </Button>
      </DialogActions>
    </Dialog>
  );
};
