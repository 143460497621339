export const FAQ_DATA = [
  {
    id: 1,
    question: "What is UPCX?",
    answer:
      "UPCX is a super app-enabled open-source payment system employing:\n1.a blockchain that enables high-speed payment settlement at the same level as existing financial institutions.\n2.an ecosystem that makes financial transactions more efficient and multi-functional\n3.an integrated development and usage environment that makes it easy for anyone to participate.",
    showAnswer: false,
  },
  {
    id: 2,
    question: "What is UPC?",
    answer:
      "UPC is the native token of UPCX and is used for multiple purposes, including processing transactions and maintaining security. The total issued amount is 780,000,000 tokens, and the market supply will increase as the ecosystem expands.",
    showAnswer: false,
  },
  {
    id: 3,
    question: "Where can I get UPCs?",
    answer:
      "UPCs are listed on several leading CEXs and can be purchased there.\nFor more information, please check the Coin Market Cap link below.\n<a href='https://coinmarketcap.com/currencies/upcx/' target='_blank'>https://coinmarketcap.com/currencies/upcx/</a>\nPlease note that residents of Japan are not eligible to purchase the tokens.",
    showAnswer: false,
  },
  {
    id: 4,
    question: "What is the overview of the staking program?",
    answer:
      "You can earn rewards by staking UPC for a period of 180 days, 90 days, or 30 days. The total number of stakes is limited to 1,000,000 UPC and is FCFS.\nPlease note that US residents cannot participate in this staking campaign.",
    showAnswer: false,
  },
  {
    id: 5,
    question: "What is the overview of the referral program?",
    answer:
      "If you participate in staking, you can be issued a referral code. You can earn 20% of the staking rewards earned by users who participate in staking by entering the referral code.",
    showAnswer: false,
  },
];
