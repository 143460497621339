import { Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { LOCAL_STRAGE_KEY } from "../common/const";
import { RegionModal } from "../component/RegionModal";
import { OpenAPI } from "../openapi";
import { ShowProgress } from "../state/ShowProgress";

export const WrappedLayout: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const navigate = useNavigate();

  const [showProgress] = useRecoilState(ShowProgress);

  const [initialized, setInitiallized] = useState<boolean>(false);

  const [showRegionModal, setShowRegionModal] = useState<boolean>(false);

  OpenAPI.BASE = process.env.REACT_APP_BACKEND_ENDPOINT as string;

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!initialized) {
      setInitiallized(true);
      return;
    }
    axios
      .get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`)
      .then((res) => {
        if (res.data.country === "US") {
          navigate("/not_allowed_area");
        } else if (res.data.country === "JP") {
          if (pathname === "/") {
            setShowRegionModal(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [initialized, pathname]);

  return (
    <>
      {children}
      <Backdrop sx={{ color: "#fff", zIndex: 10000 }} open={showProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <RegionModal
        open={showRegionModal}
        handleClose={() => setShowRegionModal(false)}
      />
    </>
  );
};
