import { Header } from "./Header";
import "../asset/style/main_layout.scss";
import { Footer } from "./Footer";

type Props = {
  tab?: number;
  children: any;
};

export const MainLayout = ({ tab, children }: Props) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};
