import { WalletContext, WalletContextValue } from "../context/WalletContext";
import { useContext } from "react";

export const useWallletContext = () => {
  const context = useContext(WalletContext) as WalletContextValue;
  if (!context) {
    throw new Error(
      "Make sure to only call useWalletContext within a  <WalletProvider>"
    );
  }
  return context;
};
