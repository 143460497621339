import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { WrappedLayout } from "./layout/WrappedLayout";
import { Home } from "./page/Home";
import { NotAllowedArea } from "./page/NotAllowedArea";
import { Referral } from "./page/Referral";
import { Reward } from "./page/Reward";
import { Stake } from "./page/Stake";

export function Body() {
  return (
    <Routes>
      <Route
        element={
          <WrappedLayout>
            <Outlet />
          </WrappedLayout>
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="/stake" element={<Stake />} />
        <Route path="/reward" element={<Reward />} />
        <Route path="/referral" element={<Referral />} />
        <Route path="/not_allowed_area" element={<NotAllowedArea />} />
      </Route>
    </Routes>
  );
}
