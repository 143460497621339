import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Button, Typography } from "@mui/material";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { BrowserProvider, Contract, Interface } from "ethers";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import Web3 from "web3";
import { METAMASK_ERROR_CODE } from "../common/const";
import {
  fromDecimalData,
  getFormattedDatetimeString,
  getFormattedNumberString,
  getReward,
  getSimpleWalletAddress,
  toDecimalData,
  waitTx,
} from "../common/helper";
import STAKING_ABI from "../common/Staking_abi.json";
import UPC_ABI from "../common/UPC_abi.json";
import { useWallletContext } from "../hook/useWalletContext";
import { ReferralService, StakingService, StatusService } from "../openapi";
import { ShowProgress } from "../state/ShowProgress";

export const Stake = () => {
  const { walletProvider } = useWeb3ModalProvider();
  const { currentAddress } = useWallletContext();

  const [, setShowProgress] = useRecoilState(ShowProgress);

  const [params] = useSearchParams();

  const [days, setDays] = useState<number>(
    params.get("period")
      ? isNaN(parseInt(params.get("period")!))
        ? 180
        : parseInt(params.get("period")!)
      : 180
  );
  const [amount, setAmount] = useState<string>("");
  const [code, setCode] = useState<string>("");

  const [balance, setBalance] = useState<number>(0);
  const [stakingAmount, setStakingAmount] = useState<number>(0);
  const [referralCode, setReferralCode] = useState<string | undefined>();

  const [apy, setApy] = useState<number>(100);

  const [periods, setPeriods] = useState<any[]>([[], [], [], []]);

  const [allowance, setAllowance] = useState<number>(0);

  const [currentDate, setCurrentDate] = useState<string>(
    getFormattedDatetimeString(new Date())
  );

  setTimeout(() => {
    setCurrentDate(getFormattedDatetimeString(new Date()));
  }, 1000);

  useEffect(() => {
    getPeriods();
    if (!currentAddress) {
      return;
    }

    getUPCBalance();
    getStakingAmount();
    getReferralCode();
    getAllowance();
  }, [currentAddress]);

  useEffect(() => {
    switch (days) {
      case 180:
        setApy(100);
        break;
      case 90:
        setApy(50);
        break;
      case 30:
        setApy(20);
        break;
    }
  }, [days]);

  const getUPCBalance = async () => {
    const provider = new BrowserProvider(walletProvider!);
    const signer = await provider.getSigner();

    const upcToken = new Contract(
      process.env.REACT_APP_UPC_ADDRESS!,
      new Interface(UPC_ABI).format(false),
      signer
    );

    const balance = await upcToken.balanceOf(currentAddress);

    setBalance(parseFloat(fromDecimalData(balance)));
  };

  const getStakingAmount = () => {
    StakingService.getTotalStakingInfoByAddress(currentAddress!)
      .then((res) => {
        setStakingAmount(res.ongoing.stakeAmount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReferralCode = () => {
    ReferralService.getReferralCode(currentAddress!)
      .then((res) => {
        setReferralCode(res.code);
      })
      .catch((err) => {
        setReferralCode(undefined);
        console.log(err);
      });
  };

  const getPeriods = () => {
    StatusService.getPeriods()
      .then((res) => {
        const periods = JSON.parse(res.value);

        setPeriods(periods);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllowance = async () => {
    const provider = new BrowserProvider(walletProvider!);
    const signer = await provider.getSigner();

    const upcToken = new Contract(
      process.env.REACT_APP_UPC_ADDRESS!,
      new Interface(UPC_ABI).format(false),
      signer
    );

    const allowance = await upcToken.allowance(
      currentAddress,
      process.env.REACT_APP_STAKING_CONTRACT_ADDRESS
    );

    setAllowance(parseFloat(fromDecimalData(allowance)));
  };

  const getStakablePeriod = () => {
    switch (days) {
      case 180:
        return periods[1];
      case 90:
        return periods[2];
      case 30:
        return periods[3];
    }
    return [];
  };

  const handleGenerateCode = async () => {
    const provider = new BrowserProvider(walletProvider!);
    const signer = await provider.getSigner();

    const stakingContract = new Contract(
      process.env.REACT_APP_STAKING_CONTRACT_ADDRESS!,
      new Interface(STAKING_ABI).format(false),
      signer
    );

    setShowProgress(true);
    stakingContract
      .generateReferralCode()
      .then((tx) => {
        waitTx(
          tx.hash,
          () => {
            setShowProgress(false);
            toast.success("Referral Code has been generated.");
            getReferralCode();
          },
          () => {
            setShowProgress(false);
            toast.error("Unexpected exception");
          }
        );
      })
      .catch((err) => {
        setShowProgress(false);
        if (err.code === METAMASK_ERROR_CODE.USER_REJECT) {
          return;
        }

        toast.error("Transaction reverted");
      });
  };

  const handleApprove = async () => {
    if (parseFloat(amount) > balance) {
      toast.warn("Not enough UPC");
      return;
    }

    const provider = new BrowserProvider(walletProvider!);
    const signer = await provider.getSigner();

    const upcToken = new Contract(
      process.env.REACT_APP_UPC_ADDRESS!,
      new Interface(UPC_ABI).format(false),
      signer
    );

    setShowProgress(true);
    upcToken
      .approve(
        process.env.REACT_APP_STAKING_CONTRACT_ADDRESS,
        toDecimalData(amount)
      )
      .then(async (tx) => {
        await tx.wait();
        setShowProgress(false);
        toast.success("UPC token has been approved successfully.");
        getAllowance();
      })
      .catch((err) => {
        setShowProgress(false);
        if (err.code === METAMASK_ERROR_CODE.USER_REJECT) {
          return;
        }

        toast.error("Transaction reverted");
      });
  };

  const getStakingID = () => {
    switch (days) {
      case 180:
        return 1;
      case 90:
        return 2;
      case 30:
        return 3;
    }
    return 0;
  };

  const handleStake = async () => {
    if (code) {
      if (!/[A-Z]{6,6}/.test(code)) {
        toast.warn("Please input correct referral code.");
        return;
      }
    }

    if (code === referralCode) {
      toast.warn("It is not allowed to use own referral code.");
      return;
    }

    const provider = new BrowserProvider(walletProvider!);
    const signer = await provider.getSigner();

    const stakingContract = new Contract(
      process.env.REACT_APP_STAKING_CONTRACT_ADDRESS!,
      new Interface(STAKING_ABI).format(false),
      signer
    );

    setShowProgress(true);
    stakingContract
      .stake(toDecimalData(amount), getStakingID(), code ?? "")
      .then((tx) => {
        waitTx(
          tx.hash,
          () => {
            setShowProgress(false);
            toast.success("UPC token has been staked successfully.");

            getStakingAmount();

            setAmount("");
            setCode("");

            setTimeout(() => {
              getUPCBalance();
              getAllowance();
            }, 1000);
          },
          () => {
            setShowProgress(false);
            toast.error("Unexpected exception");
          }
        );
      })
      .catch((err) => {
        setShowProgress(false);
        if (err.code === METAMASK_ERROR_CODE.USER_REJECT) {
          return;
        }

        toast.error("Transaction reverted");
      });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode!).then(() => {
      toast.success("Copied");
    });
  };

  const isStakable = () => {
    const period = getStakablePeriod();

    for (let i = 0; i < period.length; i++) {
      if (
        Date.now() >= period[i].from * 1000 &&
        Date.now() <= period[i].to * 1000
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title text-center mb-20">
            <h2 className="title">Stake UPC</h2>
          </div>
        </div>
      </div>
      <Typography className="stake-page-description" variant="h4">
        Stake UPC to receive more UPC.
      </Typography>
      {currentAddress && (
        <Box className="stake-page-wallet-detail-wrapper">
          <Box className="stake-page-wallet-detail-container">
            <Box className="stake-page-wallet-detail-sub-container">
              <Typography className="stake-page-wallet-detail-sub-title">
                Available to stake
              </Typography>
              <Typography className="stake-page-wallet-detail-sub-description">
                {getFormattedNumberString(balance)} UPC
              </Typography>
            </Box>
            <Box display="flex" sx={{ marginLeft: "auto" }}>
              <Typography className="stake-page-wallet-detail-address">
                {getSimpleWalletAddress(currentAddress, 2, 6)}
              </Typography>
              <AccountBalanceWalletOutlinedIcon className="stake-page-wallet-detail-wallet-icon" />
            </Box>
          </Box>
          <Box className="stake-page-wallet-detail-divider" />
          <Box className="stake-page-wallet-detail-container">
            <Box className="stake-page-wallet-detail-sub-container">
              <Typography className="stake-page-wallet-detail-sub-title">
                Your Staking Amount
              </Typography>
              <Typography className="stake-page-wallet-detail-sub-description">
                {getFormattedNumberString(stakingAmount)} UPC
              </Typography>
            </Box>
            <Box
              className="stake-page-wallet-detail-sub-container"
              sx={{ marginLeft: "auto" }}
            >
              <Typography
                className="stake-page-wallet-detail-sub-title"
                textAlign="right"
              >
                Your Referral Code
              </Typography>
              {referralCode ? (
                <Typography
                  textAlign="right"
                  className="stake-page-wallet-detail-sub-description"
                >
                  {referralCode}
                  <ContentCopyIcon
                    className="stake-page-referral-code-copy-icon"
                    onClick={handleCopy}
                  />
                </Typography>
              ) : (
                <>
                  {stakingAmount ? (
                    <Button
                      onClick={handleGenerateCode}
                      className="generate-code-button"
                    >
                      Generate
                    </Button>
                  ) : (
                    <Typography
                      textAlign="right"
                      className="stake-page-wallet-detail-sub-code-description"
                    >
                      You can generate your referral code after staking.
                    </Typography>
                  )}
                  <Typography
                    textAlign="right"
                    className="stake-page-wallet-detail-sub-code-description"
                  >
                    Network fees will be charged for code generation.
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}
      {/* <Card className="stake-page-stake-card"> */}
      <div className="document-form-wrap">
        <div className="form-grp">
          <select
            name="period"
            id="period"
            value={days}
            onChange={(e) => setDays(parseInt(e.target.value))}
          >
            <option value={180}>180 Days</option>
            <option value={90}>90 Days</option>
            <option value={30}>30 Days</option>
          </select>
          <Box className="stake-page-detail-wrapper">
            <Typography className="stake-page-detail-text">
              Stakable Period(UTC+0)
              <br />
              {currentDate}
            </Typography>
            {getStakablePeriod().length === 0 ? (
              <Typography
                className="stake-page-detail-text"
                sx={{ marginLeft: "auto" }}
              >
                Not Stakable
              </Typography>
            ) : (
              <Box className="stake-page-detail-multiline-wrapper">
                {getStakablePeriod().map((period: any, index: number) => {
                  return (
                    <Typography
                      key={index}
                      className="stake-page-detail-text"
                      sx={{ marginLeft: "auto" }}
                    >
                      {`${getFormattedDatetimeString(
                        new Date(period.from * 1000),
                        true
                      )} ~ ${getFormattedDatetimeString(
                        new Date(period.to * 1000),
                        true
                      )}`}
                    </Typography>
                  );
                })}
              </Box>
            )}
          </Box>
        </div>
        <div className="form-grp">
          <input
            type="text"
            placeholder="Amount"
            name="amount"
            value={amount}
            onChange={(e) =>
              setAmount(e.target.value.replaceAll(/[^\d.-]+/g, ""))
            }
            disabled={!isStakable()}
          />
        </div>
        <div className="form-grp">
          <input
            type="text"
            placeholder="Referral Code"
            name="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            disabled={!isStakable()}
          />
        </div>
        <Box className="stake-page-button-wrapper">
          <Typography className="stake-page-button-title">STEP 1</Typography>
          <button
            className="btn card_btn stake-page-button"
            disabled={
              currentAddress === undefined ||
              isNaN(parseFloat(amount)) ||
              parseFloat(amount) <= allowance ||
              !isStakable() ||
              parseFloat(amount) <= 0
            }
            onClick={() => handleApprove()}
          >
            Approve
          </button>
        </Box>
        <Box className="stake-page-button-wrapper">
          <Typography className="stake-page-button-title">STEP 2</Typography>
          <button
            className="btn card_btn stake-page-button"
            disabled={
              currentAddress === undefined ||
              isNaN(parseFloat(amount)) ||
              parseFloat(amount) > allowance ||
              !isStakable() ||
              parseFloat(amount) <= 0
            }
            onClick={handleStake}
          >
            Stake Now
          </button>
        </Box>
        <Box className="stake-page-detail-wrapper">
          <Typography className="stake-page-detail-text">
            You will receive
          </Typography>
          <Typography
            className="stake-page-detail-text"
            sx={{ marginLeft: "auto" }}
          >
            {getFormattedNumberString(getReward(days, apy, parseFloat(amount)))}{" "}
            UPC
          </Typography>
        </Box>
        <Box className="stake-page-detail-wrapper">
          <Typography className="stake-page-detail-text">APY</Typography>
          <Typography
            className="stake-page-detail-text"
            sx={{ marginLeft: "auto" }}
          >
            {apy}%
          </Typography>
        </Box>
      </div>
    </>
  );
};
