import { Link, useLocation } from "react-router-dom";
import { MENU_DATA } from "../common/MenuData";

export const NavMenu = () => {
  const currentRoute = useLocation().pathname;

  const isMenuItemActive = (menuLink: string) => {
    return currentRoute === menuLink;
  };

  const isSubMenuItemActive = (subMenuLink: string) => {
    return currentRoute === subMenuLink;
  };

  return (
    <ul className="navigation">
      {MENU_DATA.map((menu: any) => (
        <li
          key={menu.id}
          className={menu.has_dropdown ? "menu-item-has-children" : ""}
        >
          <Link
            to={menu.link}
            className={`section-link ${
              isMenuItemActive(menu.link) ||
              (menu.sub_menus &&
                menu.sub_menus.some(
                  (sub_m: any) => sub_m.link && isSubMenuItemActive(sub_m.link)
                ))
                ? "active"
                : ""
            }`}
          >
            {menu.title}
          </Link>

          {menu.has_dropdown && (
            <>
              {menu.sub_menus && (
                <ul className="sub-menu">
                  {menu.sub_menus.map((sub_m: any, i: any) => (
                    <li key={i}>
                      <Link
                        to={sub_m.link}
                        className={
                          sub_m.link && isSubMenuItemActive(sub_m.link)
                            ? "active"
                            : ""
                        }
                      >
                        {sub_m.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </li>
      ))}
    </ul>
  );
};
