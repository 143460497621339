/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ReferralService {
    /**
     * @param offset
     * @param limit
     * @param address
     * @param code
     * @returns any
     * @throws ApiError
     */
    public static listReferralCodes(
        offset?: number,
        limit: number = 1,
        address?: string,
        code?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/referral',
            query: {
                'offset': offset,
                'limit': limit,
                'address': address,
                'code': code,
            },
        });
    }
    /**
     * @param address
     * @returns any
     * @throws ApiError
     */
    public static getReferralCode(
        address: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/referral/{address}',
            path: {
                'address': address,
            },
        });
    }
}
