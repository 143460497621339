import STAKE from "../asset/img/icons/stake.png";
import REFERRAL from "../asset/img/icons/referral.png";
import REWARD from "../asset/img/icons/reward.png";
import { Container } from "@mui/material";

export const HowItWorks = () => {
  return (
    <>
      <Container maxWidth="xl">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mt-160 mb-40">
              <h2 className="title">How&nbsp; UPC&nbsp; Staking&nbsp; Works</h2>
            </div>
          </div>
        </div>

        <div className="how-it-works-wrapper">
          <div className="step-item">
            <span className="step-item-title">Step 1</span>
            <div className="step-item-icon-wrapper">
              <img src={STAKE} height="44" width="44" />
            </div>
            <div className="step-item-content-wrapper">
              <h2 className="step-item-content-title">Stake</h2>
              <p className="step-item-content-text">
                Stake your UPC tokens and track your staking records
              </p>
            </div>
          </div>
          <div className="step-item">
            <span className="step-item-title">Step 2</span>
            <div className="step-item-icon-wrapper">
              <img src={REFERRAL} height="44" width="44" />
            </div>
            <div className="step-item-content-wrapper">
              <h2 className="step-item-content-title">Referral Reward</h2>
              <p className="step-item-content-text">
                Claim your referral reward from the staking records which are
                using your referral code
              </p>
            </div>
          </div>
          <div className="step-item">
            <span className="step-item-title">Step 3</span>
            <div className="step-item-icon-wrapper">
              <img src={REWARD} height="44" width="44" />
            </div>
            <div className="step-item-content-wrapper">
              <h2 className="step-item-content-title">Staking Reward</h2>
              <p className="step-item-content-text">
                Claim your reward by your staking records
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
