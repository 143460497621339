import { Link } from "react-router-dom";
import { useState } from "react";
import LOGO from "../asset/img/logo/logo.png";
import { NavMenu } from "./NavMenu";
import { Sidebar } from "./Sidebar";
import { HeaderOffcanvas } from "./HeaderOffcanvas";
import { UseSticky } from "../hook/UseSticky";
import { LangMenu } from "../component/LangMenu";

export const Header = () => {
  const sticky = UseSticky();

  const [isActive, setIsActive] = useState<boolean>(false);
  const [offCanvas, setOffCanvas] = useState<boolean>(false);

  return (
    <>
      <header id="header">
        <div
          id="sticky-header"
          className={`menu-area transparent-header ${
            sticky ? "sticky-menu" : ""
          }`}
        >
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="menu-wrap">
                  <nav className="menu-nav">
                    <div className="logo">
                      <Link to="/">
                        <img src={LOGO} alt="Logo" height={60} />
                      </Link>
                    </div>
                    <div className="navbar-wrap main-menu d-none d-lg-flex">
                      <NavMenu />
                    </div>
                    {/* <LangMenu /> */}
                    <div className="header-action">
                      <ul className="list-wrap">
                        <li className="header-login">
                          <w3m-button size="md" label="Connect" />
                        </li>
                        {/* <li className="offcanvas-menu">
                          <a
                            onClick={() => setOffCanvas(true)}
                            style={{ cursor: "pointer" }}
                            className="menu-tigger"
                          >
                            <i className="fas fa-bars"></i>
                          </a>
                        </li> */}
                      </ul>
                    </div>
                    <div
                      onClick={() => setIsActive(true)}
                      className="mobile-nav-toggler"
                    >
                      <i className="fas fa-bars"></i>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Sidebar style={false} isActive={isActive} setIsActive={setIsActive} />
      <HeaderOffcanvas offCanvas={offCanvas} setOffCanvas={setOffCanvas} />
    </>
  );
};
