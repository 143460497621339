import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Body } from "./Body";
import WalletProvider from "./provider/WalletProvider";

import "./asset/style/bootstrap.min.css";
import "./asset/style/animate.min.css";
import "./asset/style/default.css";
import "./asset/style/fontawesome-all.min.css";
import "./asset/style/style.css";
import "./asset/style/responsive.css";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <WalletProvider>
          <Body />
          <ToastContainer />
        </WalletProvider>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
