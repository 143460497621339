import { useTimer } from "react-timer-hook";
import { toast } from "react-toastify";

type Props = {
  endTime: Date;
};

export const CountdownClock = ({ endTime }: Props) => {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: endTime,
    onExpire: () => {
      toast.info("Staking has been completed");
    },
  });

  return (
    <>
      <div className="time-count day">
        <span>{days}</span>days
      </div>
      <div className="time-count hour">
        <span>{hours}</span>hours
      </div>
      <div className="time-count min">
        <span>{minutes}</span>mins
      </div>
      <div className="time-count sec">
        <span>{seconds}</span>secs
      </div>
    </>
  );
};
