import { Container } from "@mui/material";
import { MainLayout } from "../layout/MainLayout";
import "../asset/style/stake.scss";
import { Stake as StakingComponent } from "../component/Stake";
import { FAQ } from "../component/FAQ";

export const Stake = () => {
  return (
    <MainLayout>
      <Container maxWidth="sm" className="stake-page-wrapper">
        <StakingComponent />
      </Container>
      <FAQ />
    </MainLayout>
  );
};
