import { Link } from "react-router-dom";
import { getFormattedNumberString } from "../common/helper";

type Props = {
  totalStaked: number;
};

export const Contribution = ({ totalStaked }: Props) => {
  return (
    <section id="contribution" className="contribution-area pt-130 pb-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="contribution-title">
              <h2 className="title">
                <span>{getFormattedNumberString(totalStaked)}&nbsp; UPC</span>
                &nbsp; Staked
              </h2>
            </div>
            <div className="progress-wrap">
              <div className="progress" role="progressbar">
                <div
                  className="progress-bar"
                  style={{ width: `${(totalStaked * 100) / 1000000}%` }}
                ></div>
              </div>
              <h6 className="progress-title">
                {" "}
                Staking...<span>Total: 1,000,000</span>
              </h6>
            </div>
            <div className="contribution-btn">
              <Link to="/stake" className="btn card_btn">
                Stake UPC
              </Link>
              <Link
                to="https://upcx.io/whitepaper/"
                className="btn card_btn"
                target="_blank"
              >
                Read White Paper
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
