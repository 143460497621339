import { Box, Card, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ETHEREUM from "../asset/icon/ethereum-logo.svg";
import UPC from "../asset/img/icons/UPC.png";
import { getFormattedNumberString } from "../common/helper";

type Props = {
  period: number;
  apy: number;
  totalStaked: number;
  totalStakers: number;
};

export const StakingItem = ({
  period,
  apy,
  totalStaked,
  totalStakers,
}: Props) => {
  const navigate = useNavigate();

  return (
    <Card className="staking-item-wrapper">
      <Box className="staking-item-icon-wrapper ">
        <img src={ETHEREUM} alt="Ethereum" height={44} />
        <Box className="staking-item-upc-icon-wrapper">
          <img src={UPC} height={36} style={{ margin: "auto" }} />
        </Box>
      </Box>

      <Typography variant="h2" className="staking-item-title">
        Ethereum
      </Typography>
      <Typography className="staking-item-description">
        Stake any amount of UPC for <b>{period} days</b>, get daily staking
        rewards.
      </Typography>
      <Box className="staking-item-detail-wrapper">
        <Box className="staking-item-detail-item-wrapper">
          <Typography className="staking-item-apy">{apy}%</Typography>
          <Typography className="staking-item-detail-title">APY</Typography>
        </Box>
        <Box className="staking-item-detail-divider" />
        <Box className="staking-item-detail-item-wrapper" sx={{ flexGrow: 2 }}>
          <Typography className="staking-item-total-staked ">
            {getFormattedNumberString(totalStaked)} UPC
          </Typography>
          <Typography className="staking-item-detail-title">Staked</Typography>
        </Box>
        <Box className="staking-item-detail-divider" />
        <Box className="staking-item-detail-item-wrapper">
          <Typography className="staking-item-total-staked ">
            {getFormattedNumberString(totalStakers)}
          </Typography>
          <Typography className="staking-item-detail-title">Stakers</Typography>
        </Box>
      </Box>
      <button
        type="submit"
        className="btn card_btn"
        onClick={() => navigate("/stake?period=" + period)}
      >
        Stake Now
      </button>
    </Card>
  );
};
