export const NotAllowedArea = () => {
  return (
    <main>
      <section className="eg-error__area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-10">
              <div className="eg-error__content text-center">
                <h2 className="eg-error__title">451</h2>
                <p>
                  Your IP address shows you are attempting to access our service
                  from a restricted jurisdiction.
                  <br />
                  We are unable to provide services to users in your region.
                  <br />
                  We apologize for any inconvenience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
